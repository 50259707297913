import { useEffect, useState } from 'react'

export const useAppAudio = () => {
  const get_audio = () => {
    const audio = new Audio(require('../assets/sounds/song.mp3'))
    audio.crossOrigin = 'anonymous'
    return audio
  }
  const [audio] = useState(get_audio())

  const [isPlaying, setIsPlaying] = useState(false)

  const play = () => {
    audio.play()
    setIsPlaying(true)
  }

  const stop = () => {
    audio.pause()
    audio.currentTime = 0
    setIsPlaying(false)
  }

  const toggle = () => {
    if (audio.paused) {
      play()
    } else {
      stop()
    }
  }

  useEffect(() => {
    audio.addEventListener('ended', audio.play)
    return () => {
      audio.removeEventListener('ended', audio.play)
    }
  }, [audio])

  return {
    play,
    stop,
    toggle,
    isPlaying,
    audio,
  }
}
