import './index.scss'

function CustomLoader() {
  return (
    <div className="app-fuulscreen-loader-container">
      <span className="app-fuulscreen-loader"></span>
    </div>
  )
}

export default CustomLoader
