import { useEffect } from 'react'
import './App.scss'
import AppRoutes from './routes'

function App() {
  useEffect(() => {
    const spinner: HTMLDivElement = document.getElementById(
      'fullscreen-custom-spinner'
    ) as HTMLDivElement

    if (
      spinner &&
      !spinner.classList.contains('fadeout fadeout-fullscreen-loader')
    ) {
      spinner.classList.add('fadeout-fullscreen-loader')
    }
  }, [])

  return <AppRoutes />
}

export default App
