import "./index.scss"
import React, { useCallback, useEffect, useRef } from "react";

interface TVisualizer {
    isPlaying: boolean;
    audio: HTMLAudioElement;
}

let animationController: any;

const Visualizer: React.FC<TVisualizer> = (props) => {
    const { isPlaying, audio } = props;

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const source = useRef<MediaElementAudioSourceNode | null>(null);
    const analyzer = useRef<AnalyserNode | null>(null);

    const visualizeData = useCallback(() => {
        if (!canvasRef.current) return;

        animationController = window.requestAnimationFrame(visualizeData);
        if (audio.paused) {
            return cancelAnimationFrame(animationController);
        }

        const songData = new Uint8Array(140);
        analyzer.current?.getByteFrequencyData(songData);
        const bar_width = 2;
        let start = 0;
        const ctx = canvasRef.current.getContext("2d");

        if (!ctx) return;

        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        for (let i = 0; i < songData.length; i++) {
            // compute x coordinate where we would draw
            start = i * 4;
            //create a gradient for the  whole canvas
            let gradient = ctx.createLinearGradient(
                0,
                0,
                canvasRef.current.width,
                canvasRef.current.height
            );
            gradient.addColorStop(0.2, "#FFAD41");
            gradient.addColorStop(0.5, "#FFAD41");
            gradient.addColorStop(1.0, "#FFAD41");
            ctx.fillStyle = gradient;
            ctx.fillRect(start, canvasRef.current.height, bar_width, -songData[i]);
        }
    }, [audio.paused]);

    const handleAudioPlay = useCallback( () => {
        let audioContext = new AudioContext();
        if (!source.current) {
            source.current = audioContext.createMediaElementSource(audio);
            analyzer.current = audioContext.createAnalyser();
            source.current.connect(analyzer.current);
            analyzer.current.connect(audioContext.destination);
        }
        visualizeData();
    }, [visualizeData, audio]);

    useEffect(() => {
        if (isPlaying)
            handleAudioPlay();
        else if (Boolean(animationController))
            cancelAnimationFrame(animationController);
    }, [isPlaying, handleAudioPlay]);

    return (
        <canvas className="visualizer" ref={canvasRef} width={500} height={200} />
    )
}

export default Visualizer;