import './index.scss'
import { Link, useLocation } from 'react-router-dom'
import LogoS from '../../assets/images/pe.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  faLinkedin,
  faGithub,
  faSkype,
} from '@fortawesome/free-brands-svg-icons'
import { HashLink } from 'react-router-hash-link'
import { useEffect } from 'react'
const Sidebar = () => {
  const {hash, pathname} = useLocation();

  const getIsActive = (hashVal?: string) => {
    if (!hashVal && !hash) return true;

    return hashVal === hash;
  }

  useEffect(() => {
    if (!hash && !pathname) {
      window.scrollTo(0, 0)
    }
  }, [hash, pathname])

  return (
    <div className="nav-bar">
      <Link className="logo" to={'/'}>
        <img src={LogoS} alt="logo" />
        <div className="logo-subtitle">P e l u m i</div>
      </Link>

      <nav>
        <HashLink smooth className={`${getIsActive() ? 'active' : ''}`} to="/#">
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </HashLink>

        <HashLink smooth className={`${getIsActive("#about") ? 'active' : ''} about-link`} to="/#about">
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </HashLink>

        <HashLink smooth className={`${getIsActive("#contact") ? 'active' : ''} contact-link`} to="/#contact">
          <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
        </HashLink>
      </nav>

      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/akinrele-pelumi-800980169/"
          >
            <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
          </a>
        </li>

        <li>
          <a target="_blank" rel="noreferrer" href="https://github.com/peldev">
            <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
          </a>
        </li>

        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://join.skype.com/invite/GeBaWihfvbEf"
          >
            <FontAwesomeIcon icon={faSkype} color="#4d4d4e" />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Sidebar
