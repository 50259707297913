import React, { useLayoutEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import AppMusic from '../AppMusic'
import Sidebar from '../Sidebar'
import './index.scss'
import { useAppAudio } from '../../hooks/useAppAudio'
import Visualizer from '../Visualizer'
import gsap from 'gsap'

declare global {
  interface Window {
    requestAnimFrame: (callback: FrameRequestCallback) => number
  }
}

const a = 40 // How many dots to have
const b = 5 // how fast to spin
const c = 1 // how much to fade. 1 all, 0.5 half, 0 none
const d = 50 // distance from the mouse

type Point = {
  r: number
  p: { x: number | null; y: number | null }
  w: number
  c: string
  d: number
  s: number
}

const Layout = () => {
  const appCanvas = useRef<HTMLCanvasElement>(null)

  const { 
    isPlaying,
    toggle,
    audio
  } = useAppAudio();

  useLayoutEffect(() => {
    const canvas = appCanvas.current
    if (canvas) {
      gsap.registerPlugin()

      const ctx = canvas.getContext('2d')
      const points: Point[] = []
      const m: { x: number; y: number } = { x: 0, y: 0 }

      canvas.width = window.innerWidth
      canvas.height = window.innerHeight

      m.x = canvas.width / 2
      m.y = canvas.height / 2

      const listener = (e: MouseEvent) => {
        gsap.to(m, {
          x: e.clientX,
          y: e.clientY,
          ease: 'linear',
          duration: 0.3,
        })
      }

      window.addEventListener('mousemove', listener)

      for (var i = 0; i < a; i++) {
        points.push({
          r: (360 / a) * i,
          p: { x: null, y: null },
          w: Math.random() * 5,
          c: '#ffac41',
          d: Math.random() * (d + 5) - 5,
          s: Math.random() * (b + 5) - 5,
        })
      }

      const render = () => {
        if (ctx == null) return

        if (m.x == null || m.y == null) return

        ctx.fillStyle = 'rgba(0,0,0,' + c + ')'
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        ctx.lineCap = 'round'

        for (var i = 0; i < points.length; i++) {
          var p = points[i]

          p.r += p.s
          if (p.r >= 360) p.r = p.r - 360

          var vel = {
            x: p.d * Math.cos((p.r * Math.PI) / 180),
            y: p.d * Math.sin((p.r * Math.PI) / 180),
          }

          if (p.p.x != null && p.p.y != null) {
            ctx.strokeStyle = p.c
            ctx.lineWidth = 2
            ctx.beginPath()
            ctx.moveTo(p.p.x, p.p.y)
            ctx.lineTo(m.x + vel.x, m.y + vel.y)
            ctx.stroke()
            ctx.closePath()
          }

          p.p.x = m.x + vel.x
          p.p.y = m.y + vel.y
        }
      }

      window.requestAnimFrame = (function () {
        return (
          window.requestAnimationFrame ||
          function (callback) {
            window.setTimeout(callback, 1000 / 60)
          }
        )
      })()
      ;(function animloop() {
        window.requestAnimFrame(animloop)
        render()
      })()
    }
  }, [])

  return (
    <>
      <canvas id="appCanvas" ref={appCanvas} className="c"></canvas>

      <AppMusic isPlaying={isPlaying} toggle={toggle}  />

      <Sidebar />

      <Visualizer 
        audio={audio}
        isPlaying={isPlaying}
      />

      <div className="page">
        <span className="tags top-tags">&lt;body&gt;</span>

        <Outlet context={{ appCanvas: appCanvas }} />

        <span className="tags bottom-tags">
          &lt;/body&gt;
          <br />
          <span className="bottom-tag-html">&lt;/html&gt;</span>
        </span>
      </div>
    </>
  )
}

export default Layout
