import './index.scss'

export type Props = {
  isPlaying: boolean;
  toggle: VoidFunction;
};

function AppMusic(props: Props) {
  const { isPlaying, toggle } = props;  

  return (
    <>
      <div
        className={`music-button ${
          isPlaying ? 'music-button-active' : undefined
        }`}
        onClick={toggle}
      >
        <span className="sound">Sound</span>&nbsp;
        <span className={isPlaying ? 'on' : 'off'}>
          {isPlaying ? 'ON' : 'OFF'}
        </span>
      </div>
    </>
  )
}

export default AppMusic
